import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import {
  ART_CLUB_SALES_LANDING_LINK,
  MASTERY_PROGRAM_SALES_LANDING_LINK,
} from '~/shared/constants';

export const guestMenuItems: IMenuItem[] = [
  {
    title: 'Feed',
    icon: 'user-menu/feed',
    to: '/feed',
    vendorIconPath: require('~/assets/img/icons/feed.svg'),
    vendorActiveIconPath: require('~/assets/img/icons/feed-active.svg'),
    isFeedLink: true,
    showInLanding: false,
  },
  {
    title: 'Communities',
    icon: 'user-menu/communities',
    to: '/communities',
    showInLanding: false,
  },
  {
    title: 'Education',
    icon: 'user-menu/school',
    href: 'https://www.milanartinstitute.com/online-learning',
    showInLanding: true,
  },
  {
    title: 'Mastery Program',
    icon: 'user-menu/mastery-program',
    href: MASTERY_PROGRAM_SALES_LANDING_LINK,
    showInLanding: false,
  },
  {
    title: 'Art Club',
    icon: 'user-menu/art-club',
    href: ART_CLUB_SALES_LANDING_LINK,
    showInLanding: true,
  },
  {
    title: 'Blog',
    to: '/blog',
    icon: 'user-menu/blog',
    showInLanding: false,
  },
  {
    title: 'Art Store',
    icon: 'user-menu/art-store',
    href: 'https://www.milanartstore.com/',
    showInLanding: true,
  },
  {
    title: 'Art Gallery',
    icon: 'user-menu/gallery',
    href: 'https://artsocial.gallery/',
    showInLanding: true,
  },
  {
    title: 'Live Workshop',
    to: '/workshop',
    icon: 'user-menu/workshop',
    showInLanding: false,
  },
  // {
  //   title: 'Auctions',
  //   icon: 'user-menu/auctions',
  //   href: 'https://auction.artsocial.com/',
  //   showInMobileSidebar: true,
  // },
];

export const guestMenuItemsDesktop: IMenuItem[] = [
  {
    title: 'Social',
    items: [
      {
        title: 'Feed',
        to: '/feed',
      },
      {
        title: 'Communities',
        to: '/communities',
      },
    ],
  },
  ...guestMenuItems.filter((item) => item.showInLanding),
  {
    title: 'More',
    items: [
      {
        title: 'Live Workshops',
        to: '/workshop',
      },
      // {
      //   title: 'Auctions',
      //   icon: 'user-menu/auctions',
      //   href: 'https://auction.artsocial.com/',
      // },
      {
        title: 'Blog',
        to: '/blog',
      },
      {
        title: 'About',
        to: '/about',
      },
    ],
  },
];
