import { IMenuItem } from '~/shared/interfaces/menu-item.interface';
import {
  ART_CLUB_SALES_LANDING_LINK,
  MASTERY_PROGRAM_SALES_LANDING_LINK,
} from '~/shared/constants';
import {
  MASTERY_PROGRAM_ORIENTATION_SLUG,
  MASTERY_PROGRAM_SLUG,
} from '~/features/courses/constants/index.constant';
import { ELinkTargetType } from '~/shared/enums/link-target.enum';

export const userSidebarLinks: IMenuItem[] = [
  {
    title: 'Feed',
    icon: 'user-menu/feed',
    to: '/feed',
    vendorIconPath: require('~/assets/img/icons/feed.svg'),
    vendorActiveIconPath: require('~/assets/img/icons/feed-active.svg'),
    showInMobileBottomMenu: true,
    showInLanding: false,
    showInMobileSidebar: true,
    isFeedLink: true,
  },
  {
    title: 'Communities',
    icon: 'user-menu/communities',
    to: '/communities',
    showInLanding: false,
    showCommunityRequestsNotification: true,
    showInMobileSidebar: true,
  },
  {
    title: 'Education',
    icon: 'user-menu/school',
    href: 'https://www.milanartinstitute.com/online-learning',
    showInLanding: true,
    showInMobileSidebar: true,
  },
  {
    title: 'Mastery Program',
    icon: 'user-menu/mastery-program',
    to: `/courses/${MASTERY_PROGRAM_SLUG}`,
    href: MASTERY_PROGRAM_SALES_LANDING_LINK,
    showInLanding: false,
    isWithNewBadge: true,
    isMasteryProgram: true,
    showInMobileSidebar: true,
  },
  {
    title: 'MP Prep Course',
    vendorIconPath: require('~/assets/img/icons/mp-orientation.svg'),
    vendorActiveIconPath: require('~/assets/img/icons/mp-orientation-active.svg'),
    to: `/courses/${MASTERY_PROGRAM_ORIENTATION_SLUG}`,
    showInLanding: false,
    showInMobileSidebar: true,
    isMpOrientationCourse: true,
  },
  {
    title: 'Messages',
    mobileTitle: 'Chat',
    icon: 'user-menu/messages',
    to: '/messages',
    showInLanding: false,
    showMessagesCount: true,
    showInMobileBottomMenu: true,
  },
  {
    title: 'Pro Library',
    to: '/pro-library',
    icon: 'user-menu/pro-library',
    showInLanding: false,
    showInMobileSidebar: true,
    isProLibrary: true,
  },
  {
    title: 'Art Club',
    icon: 'user-menu/art-club',
    to: '/users/art-club/browse',
    hideInArtClub: true,
    showInLanding: true,
    showInMobileBottomMenu: true,
    artClubSubscriptionRequired: true,
    showInMobileSidebar: true,
    parentRouteName: 'users-art-club',
  },
  {
    title: 'Blog',
    to: '/blog',
    icon: 'user-menu/blog',
    showInLanding: false,
    showInMobileSidebar: true,
  },
  {
    title: 'Art Store',
    icon: 'user-menu/art-store',
    href: 'https://www.milanartstore.com/',
    showInLanding: true,
    showInMobileSidebar: true,
  },
  {
    title: 'Art Gallery',
    icon: 'user-menu/gallery',
    href: 'https://artsocial.gallery/',
    showInLanding: true,
    showInMobileSidebar: true,
  },
  {
    title: 'Live Workshop',
    icon: 'user-menu/workshop',
    to: '/workshop',
    showInLanding: false,
    showInMobileSidebar: true,
  },
  // {
  //   title: 'Auctions',
  //   icon: 'user-menu/auctions',
  //   href: 'https://auction.artsocial.com/',
  //   showInMobileSidebar: true,
  // },
];

export const userPublicMenuItems: IMenuItem[] = [
  {
    title: 'Social',
    items: [
      {
        title: 'Feed',
        to: '/feed',
      },
      {
        title: 'Communities',
        to: '/communities/my',
      },
    ],
  },
  ...userSidebarLinks.filter((item) => item.showInLanding),
  {
    title: 'More',
    items: [
      {
        title: 'Live Workshops',
        to: '/workshop',
      },
      // {
      //   title: 'Auctions',
      //   icon: 'user-menu/auctions',
      //   href: 'https://auction.artsocial.com/',
      // },
      {
        title: 'Blog',
        to: '/blog',
      },
      {
        title: 'About',
        to: '/about',
      },
    ],
  },
];

export const userBottomBarLinks = [
  ...userSidebarLinks.filter((item) => item?.showInMobileBottomMenu),
  {
    title: 'Profile',
    to: '/users/:userId',
    icon: 'user-menu/profile',
  },
];

export const userBottomBarLinksForNonArtClub: IMenuItem[] = userBottomBarLinks.map((item) =>
  item?.artClubSubscriptionRequired
    ? {
        ...item,
        to: undefined,
        href: ART_CLUB_SALES_LANDING_LINK,
        target: ELinkTargetType.Self,
      }
    : item,
);

export const userSidebarLinksForArtClubPages = userSidebarLinks.filter(
  (item) => !item?.hideInArtClub,
);

export const userMobSidebarLinks: IMenuItem[] = userSidebarLinks.filter(
  (item) => item.showInMobileSidebar,
);

export const userMobSidebarLinksForArtClubPages: IMenuItem[] = userMobSidebarLinks.filter(
  (item) => !item?.hideInArtClub,
);

export const userSidebarLinksForNonArtClubSubscribed: IMenuItem[] = userSidebarLinks.map((item) =>
  item?.artClubSubscriptionRequired
    ? {
        ...item,
        to: undefined,
        href: ART_CLUB_SALES_LANDING_LINK,
        target: ELinkTargetType.Self,
      }
    : item,
);

export const userMobSidebarLinksForNonArtClubSubscribed: IMenuItem[] = userSidebarLinksForNonArtClubSubscribed.filter(
  (item) => item.showInMobileSidebar,
);
