export const FRONTEND_MAIN_DOMAIN = 'https://www.artsocial.com';
export const MILAN_ART_ACCOUNT_ID = 'c38e2f84-8211-11ec-a8a3-0242ac120002';
export const MILAN_ART_CLUB_ACCOUNT_ID = '4bc6b53e-1838-4f95-8384-6c6e611ef90d';
export const MILAN_ART_STORE_ACCOUNT_ID = '63e2b412-99d6-48cc-9bb0-8997278a0fbe';
export const MILAN_ART_GALLERY_ACCOUNT_ID = 'acc79c0c-6052-4629-8935-35484fd92935';
export const MOBILE_WIDTH = 821;
export const TABLET_WIDTH = 1281;
export const PORTFOLIO_ART_LIMIT = 30;
export const MAX_POST_TEXT_LENGTH = 8000;
export const MAX_COMMENT_TEXT_LENGTH = 1000;
export const MAX_POST_CROPPED_TEXT_LENGTH_DESKTOP = 450;
export const MAX_POST_CROPPED_TEXT_LENGTH_MOBILE = 270;
export const POST_IMAGES_COUNT_SHOWN_IN_DESKTOP = 3;
export const DEFAULT_LIKED_BY_USERS_COUNT = 30;
export const MILAN_ART_APPLE_APP_ID = '1616194472';
export const MILAN_ART_GOOGLE_APP_ID = 'com.milanart';
export const MILAN_ART_GOOGLE_URL = `https://play.google.com/store/apps/details?id=${MILAN_ART_GOOGLE_APP_ID}`;
export const MILAN_ART_APPLE_URL = `https://apps.apple.com/us/app/milan-art/id${MILAN_ART_APPLE_APP_ID}`;
export const CONTACT_US_MESSENGER_LINK =
  'https://www.messenger.com/t/134665446602802/?messaging_source=source%3Apages%3Amessage_shortlink';
export const DELETE_PROTECTION_KEY = 'DELETE';
export const DEFAULT_ADMIN_TIMEZONE = 'America/New_York';
export const MAX_META_POST_CONTENT_LENGTH = 50;
export const MAX_CONTACT_US_MESSAGE_LENGTH = 1000;
export const ART_CLUB_SALES_LANDING_LINK = 'https://masteryprogram.com/art-club';
export const MASTERY_PROGRAM_SALES_LANDING_LINK = 'https://masteryprogram.com';
export const MASTERY_MENTORING_SALES_LANDING_LINK =
  'https://masteryprogram.com/personal-artist-mentors';
export const MASTERY_PROGRAM_PERSONAL_ARTIST_MENTORS_LANDING_LINK =
  'https://masteryprogram.com/personal-artist-mentors';
export const GROUP_MENTORING_COMMUNITY_ID = 'f0f8d14c-26b9-42a2-ba07-0a64c30f33b9';
export const MASTERY_MENTORING_GRADUATES_COMMUNITY_ID = '63437b8d-8b61-4117-b034-963ad813e7c9';
export const FACEBOOK_VERIFICATION_ID = '3o87t3o0ww4qc6qqdw5q7mx1khc1d5';
export const MAX_POST_LINK_LENGTH = 50;
export const STATIC_PAGES_LAST_MODIFIED_DEFAULT = '2023-02-23T00:00:00.000Z';
export const MAX_USERNAME_LENGTH = 30;
export const BLURHASH_DEFAULT_RESOLUTION = 32;
export const OAUTH_COOKIE_KEY = 'artSocialOAuthToken';
