/* eslint-disable import/order */
import Vue from 'vue'
import merge from '~lodash.mergewith'
import * as Sentry from '~@sentry/vue'
import { BrowserTracing } from '~@sentry/tracing'
import { ExtraErrorData, RewriteFrames } from '~@sentry/integrations'

export default async function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    Vue,
    release:"9d8de42aefc4f751518261e31b4b68565f2f2fee",
    dsn:"https:\u002F\u002F0076b08484c84f6cbc1088c37847e853@o1091547.ingest.sentry.io\u002F6108629",
    environment:"production",
    tracesSampleRate:0.45,
  }

  config.integrations = [
    new ExtraErrorData(),
    new RewriteFrames(),
  ]

  // eslint-disable-next-line prefer-regex-literals
  const { browserTracing, vueOptions, ...tracingOptions } = {"tracesSampleRate":0.45,"browserTracing":{"tracePropagationTargets":["localhost","https:\u002F\u002Fapi.artsocial.com","https:\u002F\u002Fapi.milanartinstitute.net"]},"vueOptions":{"trackComponents":true}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.app.router) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
